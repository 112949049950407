import React from 'react';

export default function FourOhFourPage() {
  return (
    <>
      <h1>Oh no, the page you requested deesn't exist!</h1>
      <p>Sorry! this page doesn't seem to exist in the system.</p>
      <p>Error 404.</p>
    </>
  );
}
